:root {
    --button-background-color: rgb(147, 100, 81);
    --button-background-color-hover: rgb(147, 100, 81, 0.8);
    --main-background-color: rgb(236, 228, 217);
    --main-background-color-hover: rgb(236, 228, 217, 0.8);
    --accent-color: rgb(96, 125, 190);
    --book-background-color: rgb(96, 125, 190);
    --another-accent-color: rgb(119, 144, 88);
    --header-height: 60px;
    --header-margin: 40px;
    --header-padding: 60px;
    --main-font-size: 12px;
    --small-screen-font-size: 10px;
    --safe-area-inset-bottom: env(safe-area-inset-bottom, 0px);
    --safe-area-inset-top: env(safe-area-inset-top, 0px);
    --vh: 1vh;
}

@font-face {
    font-family: 'Play';
    src: url('./assets/fonts/Play-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Play Bold';
    src: url('./assets/fonts/Play-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

body {
    font-family: 'Play Bold', sans-serif;
    font-weight: 700;
    margin: 0;
    padding: 0;
    /*background-image: url('assets/robot_background.png');*/
    background-color: var(--button-background-color);
    backdrop-filter: blur(5px) saturate(100%);
    height:100vh;
    color: var(--main-background-color);
    border-color: var(--main-background-color) !important;
}
.fill-available {
    height: -webkit-fill-available;
}
.border-white {
    border-color: var(--main-background-color) !important;
}

.landing {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    background: rgba(236, 228, 217, 0.3);
    border-radius: 20px;
    margin: 40px;
}
.landing-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 200px;
    h2 {
        font-size: 24px;
        font-weight: bold;
        /*color: var(--button-background-color);*/
    }
    p {
        font-size: 16px;
        font-weight: bold;
        /*color: var(--button-background-color);*/
    }
}
.landing-button {
    padding: 10px 20px;
    /*background-color: var(--button-background-color);*/
    color: var(--main-background-color);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: var(--main-font-size);
    font-weight: bold;
    transition: background-color 0.3s, transform 0.2s;
    text-transform: uppercase;
    margin: 10px;
    width: 90%;
}
.landing-button:hover {
    background-color: var(--button-background-color-hover);
    transform: scale(1.05); /* Slight zoom effect on hover */
}
.landing-button:active {
    background-color: var(--accent-color);
    transform: scale(0.95); /* Slight shrink effect on click */
}
.landing-right-image {
    height: 65vh;
}
.app {
    text-align: center;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: calc(var(--safe-area-inset-bottom) + 20px);
    padding-top: calc(var(--safe-area-inset-top) + 20px);
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: calc(100 * var(--vh));
    height: calc(100 * var(--vh));
    position: relative;
}

.app-logo {
    border-radius: 5px;
    backdrop-filter: blur(3px) saturate(90%);
}

.app-main {
    display: flex; /* Aligns the book list and flashcards side by side */
    flex-grow: 1; /* Allow it to grow and fill the remaining space */
    height: calc(100vh - var(--header-height) - var(--header-margin) * 2 - var(--header-padding) * 2);
    width: 100%;
}

.auth-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 350px;
    gap: 20px;
    margin-bottom: 5%;
}
.divider {
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.1); /* Barely visible line */
    margin: 20px auto;
}

.google-signin-button {
    padding: 10px 20px;
    background-color: #4285f4; /* Google Blue */
    color: white;
    border: none;
    border-radius: 5px;
    font-size: var(--main-font-size);
    cursor: pointer;
    text-transform: uppercase;
    font-weight: bold;
    transition: background-color 0.3s ease;
    width: 100%;
    max-width: 420px;
}

.google-signin-button:hover {
    background-color: #357ae8;
}

.google-signin-button:active {
    background-color: #2a65c7;
}

.email-auth {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
    width: 100%;
}

.email-auth input {
    width: 100%;
    max-width: 420px;
    padding: 10px;
    border: var(--button-background-color);
    border-radius: 5px;
    font-size: var(--main-font-size);
}

.email-auth button {
    width: 100%;
    max-width: 420px;
    padding: 10px;
    color: var(--button-background-color);
    background-color: var(--main-background-color);
    border: none;
    border-radius: 5px;
    font-size: var(--main-font-size);
    font-weight: bold;
    cursor: pointer;
    /*transition: background-color 0.3s ease;*/
}

.email-auth button:hover {
    background-color: var(--main-background-color);
}

.email-auth button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.user-profile-password-input {
    width: 100%;
    max-width: 420px;
    padding: 10px;
    border: var(--button-background-color);
    border-radius: 5px;
    font-size: var(--main-font-size);
}

.update-password-button {
    width: 100%;
    max-width: 420px;
    padding: 10px;
    color: var(--button-background-color);
    background-color: var(--main-background-color);
    border: none;
    border-radius: 5px;
    font-size: var(--main-font-size);
    font-weight: bold;
    cursor: pointer;
}

.update-password-button:hover {
    background-color: var(--main-background-color);
}


@keyframes pulsate {
    0%, 100% {
        transform: scale(1); /* Original size */
    }
    50% {
        transform: scale(1.1); /* Slightly larger size */
    }
}

.upload-button {
    display: inline-block; /* Ensure it's treated like a block element */
    padding: 10px 20px;
    background-color: var(--another-accent-color);
    color: var(--main-background-color);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: var(--main-font-size);
    font-weight: bold;
    /*transition: background-color 0.3s, transform 0.2s;*/
    text-transform: uppercase;

    /* Add the pulsate animation */
    animation: pulsate 1.5s infinite ease-in-out;
}

.upload-button:hover {
    background-color: var(--another-accent-color);
    transform: scale(1.05); /* Slight zoom effect on hover */
    animation: none; /* Disable periodic animation on hover */
}

.upload-button:active {
    background-color: var(--accent-color);
    transform: scale(0.95); /* Slight shrink effect on click */
}

.upload-loader {
    font-size: var(--main-font-size);
    font-weight: bold;
    color: var(--main-background-color);
    text-align: center;
    margin-top: 10px;
    text-transform: uppercase;
    backdrop-filter: blur(3px) saturate(20%);
}

.header-right-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 5%;
    gap: 20px;
    z-index: 2;
}

.books-available {
    color: var(--main-background-color);
    position: relative; /* Position the number over the image */
    top: 30%;
    left: 30%; /* Center horizontally */
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 90%;
    margin-top: 50%;
}
.book-logo {
    width: 40px;
    backdrop-filter: blur(3px) saturate(20%);
}

.book-number {
    position: absolute; /* Position the number over the image */
    top: 40%;
    left: 50%; /* Center horizontally */
    transform: translate(-40%, -50%); /* Offset for perfect centering */
    font-size: var(--main-font-size);
    font-weight: bold;
    color: var(--main-background-color); /* Ensure visibility over the image */
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.8); /* Add a shadow for better readability */
    pointer-events: none; /* Prevent interaction with the number */
}

.logout-section {
    display: flex;
    justify-content: center;
}

.books-section {
    width: 90%; /* Occupies 30% of the screen width */
    border-bottom: 1px solid rgba(0, 0, 0, 0.1); /* Slight border between sections */
    /*padding: 10px; !* Adds some spacing inside the section *!*/
    /*background-color: var(--main-background-color);*/
    height: fit-content;
    min-height: 50px;

}

.books-heading {
    display: none;
    font-size: 14px;
    font-weight: bold;
    /*text-transform: uppercase;*/
    color: var(--button-background-color);
}

.books-container {
    display: flex; /* Align items horizontally */
    overflow-x: auto; /* Enable horizontal scrolling */
    white-space: nowrap; /* Prevent items from wrapping */
    gap: 10px; /* Add spacing between items */
    padding: 10px; /* Add padding around the container */
    scroll-behavior: smooth; /* Smooth scrolling effect */
    box-sizing: border-box; /* Include padding and border in width calculations */
    scroll-padding-left: 200px; /* Ensure the leftmost item is fully visible */
    scroll-padding-right: 50px; /* Ensure the rightmost item is fully visible */
    /*justify-content: center;*/
}

.books-placeholder {
    width: 100%;
    font-size: var(--main-font-size);
    /*color: var(--button-background-color);*/
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
}


.book {
    background-color: var(--button-background-color);
    border: 2px solid var(--main-background-color);
}

.book:hover {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.active-book {
    background-color: var(--main-background-color); /* Match flashcard background */
    border: 2px solid var(--button-background-color); /* Optional: highlight active book */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    .book-title {
        color: var(--button-background-color);
    }
}

.book-title {
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    text-transform: capitalize;
    word-break: break-word;
    color: var(--main-background-color);
}

.book-filename {
    font-size: var(--main-font-size);
    color: var(--main-background-color);
    text-align: center;
}

.main-section {
    .book-title {
        font-size: 24px;
        font-weight: bold;
        margin-top: 20px;
        margin-bottom: 20px;
        text-transform: capitalize;
        color: var(--button-background-color);
        display: none;
        backdrop-filter: blur(5px) saturate(100%);
    }
}
.flashcard-section {

}

.flashcard-container {
    display: flex;
    flex-direction: column; /* Stack flashcard and button vertically */
    align-items: center; /* Center the content horizontally */
    gap: 20px; /* Space between flashcard and button */
}

.flashcard {
    position: relative; /* Ensure relative positioning for label alignment */
    width: 50%;
    min-width: 320px;
    min-height: 400px;
    background-color: var(--main-background-color);
    border: 2px solid var(--button-background-color);
    border-radius: 10px;
    display: flex;
    flex-direction: column; /* Align content vertically */
    justify-content: flex-start; /* Align label and content to the top */
    align-items: center; /* Center content horizontally */
    font-size: var(--main-font-size);
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    transition: transform 0.3s, box-shadow 0.3s;
}

.previous-button-container {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}
.next-button-container {
    /*   Should be at the rightmost part of the parent centered vertically */
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}
.shuffle-button,
.previous-button,
.next-button {
    opacity: 0.4;
    width: 40px;
    height: 40px;
    background-color: var(--button-background-color-hover);
    color: var(--main-background-color);
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    transition: background-color 0.3s, transform 0.2s;

}
.shuffle-button:hover,
.previous-button:hover,
.next-button:hover {
    background-color: var(--button-background-color);
    transform: scale(1.05); /* Slight zoom effect on hover */
}

.flashcard:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.flashcard-label {
    position: static; /* Remove absolute positioning */
    font-size: 32px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 40px; /* Add space between the label and content */
    color: var(--button-background-color);
}

.flashcard-answer,
.flashcard-question {
    /* Centered vertically */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    color: var(--button-background-color);
}

.loader {
    font-size: var(--main-font-size);
    color: var(--button-background-color);
    text-align: center;
    margin-top: 10px;
}

.action-buttons {

}


.logout-img {
    display: none;
    padding: 10px 20px;
    /*background-color: transparent;*/
    color: var(--main-background-color);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
    width: 20px;
    /*backdrop-filter: blur(3px) saturate(20%);*/
}

.logout-button {
    padding: 10px 20px;
    background-color: transparent;
    color: var(--main-background-color);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: var(--main-font-size);
    font-weight: bold;
    transition: background-color 0.3s, transform 0.2s;
    text-transform: uppercase;
    backdrop-filter: blur(3px) saturate(20%);
}

.logout-button:hover {
    transform: scale(1.05); /* Slight zoom effect on hover */
}

.logout-button:active {
    background-color: var(--accent-color);
    transform: scale(0.95); /* Slight shrink effect on click */
}

.login-button {
    padding: 10px 20px;
    color: var(--button-background-color);
    background-color: var(--main-background-color);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: var(--main-font-size);
    font-weight: bold;
    /*transition: background-color 0.3s, transform 0.2s;*/
    text-transform: uppercase;
    margin: 10px;
    width: 100%;
}

.login-button:hover {
    background-color: var(--main-background-color);
    /*transform: scale(1.05); !* Slight zoom effect on hover *!*/
}

.login-input {
    padding: 10px;
    border: 1px solid var(--button-background-color);
    border-radius: 5px;
    margin: 10px;
    width: 200px;
}

.forgot-password-button {
    padding: 10px 20px;
    background-color: var(--button-background-color);
    color: var(--main-background-color);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: var(--main-font-size);
    font-weight: bold;
    transition: background-color 0.3s, transform 0.2s;
    text-transform: uppercase;
    margin: 10px;
    width: 100%;
}

.forgot-password-button:hover {
    background-color: var(--button-background-color-hover);
    transform: scale(1.05); /* Slight zoom effect on hover */
}

.reset-password-button {
    padding: 10px 20px;
    background-color: var(--button-background-color);
    color: var(--main-background-color);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: var(--main-font-size);
    font-weight: bold;
    transition: background-color 0.3s, transform 0.2s;
    text-transform: uppercase;
    margin: 10px;
    width: 100%;
}

.auth-notification-message {
    font-size: var(--main-font-size);
    color: var(--main-background-color);
    font-weight: bold;
    text-transform: uppercase;
    margin: 10px;
}

.tutorial,
.payment-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Darken the background */
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    z-index: 1000;
}
.tutorial-content,
.payment-overlay-content {
    max-width: 80%; /* Optional: Restrict width */
    max-height: 80%; /* Restrict height to 80% of the screen */
    overflow-y: auto; /* Enable vertical scrolling for overflowing content */
    background-color: var(--main-background-color);
    color: var(--button-background-color);
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    padding: 20px;
    text-align: center;
}

.payment-overlay-content p {
    margin: 0 0 20px;
    font-size: var(--main-font-size);
    color: var(--button-background-color);
    font-weight: bold;
    text-transform: uppercase;
}

.side-menu-overlay {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Darken the background */
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    z-index: 1000;
}

.side-menu-overlay-content {
    position: fixed;
    top: 1rem;
    right: 0;
    width: 5rem;
    height: auto;
    background-color: var(--main-background-color);
    border-radius: 10px 0 0 10px;
}

.side-menu-item {
    color: var(--button-background-color);
    cursor: pointer;
    transition: background-color 0.3s;
}

.overlay-button {
    padding: 10px 20px;
    background-color: var(--button-background-color);
    color: var(--main-background-color);
    border: none;
    border-radius: 5px;
    font-size: var(--main-font-size);
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.overlay-button:hover {
    background-color: var(--button-background-color-hover);
}

@media only screen and (max-width: 575px) {
    .app {
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: calc(var(--safe-area-inset-bottom) + 20px);
        padding-top: calc(var(--safe-area-inset-top) + 20px);
    }

    .header-main-section {
        gap: 60px;
        top: calc(var(--header-height) - 20px); /* 10px below the top of the header */
        z-index: 2;
    }

    .app-logo {
        height: 25px;
    }

    .upload-button {
        font-size: var(--small-screen-font-size);
        z-index: 2;
    }

    .upload-loader {
        font-size: var(--small-screen-font-size);
        color: var(--button-background-color);
    }

    .books-section {
        width: 100%; /* Occupies 100% of the screen width */
        .book-title {
            font-size: var(--small-screen-font-size);
            display: inline-block;
            word-break: break-word;
        }
    }

    .books-container {
        margin-top: 15%;
        scroll-padding-left: 100px; /* Ensure the leftmost item is fully visible */
        scroll-padding-right: 20px; /* Ensure the rightmost item is fully visible */
    }

    .book {

    }

    .book-title {
        text-wrap: auto;
    }

    .main-section {
        width: 100%; /* Occupies 100% of the screen width */
        margin-bottom: 0px;
        .book-title {
            display: none;
            font-size: var(--small-screen-font-size);
            font-weight: bold;
        }
    }

    .flashcard-section {

    }

    .flashcard {
        width: 95%;
        min-width: 200px;
        min-height: 300px;
        font-size: var(--small-screen-font-size);
    }

    .action-buttons {

    }

    .download-buttons {

    }

    .download-flashcards-button {
        min-width: 5%;
        padding: 10px 10px;
        font-size: var(--small-screen-font-size);
    }

    .header-right-section {

    }

    .books-available {
        top: 50%;
        left: 50%; /* Center horizontally */
    }
    .book-number {
        /*padding-left: 1px;*/
        font-size: 12px;
    }
    .book-logo {
        width: 25px;
    }

    .logout-img {
        display: block;
    }

    .logout-button {
        display: none;
    }

    .books-heading {
        display: none;
    }

    .books-placeholder {
        font-size: var(--small-screen-font-size);
    }

    .floating-upload-button {
        bottom: calc(env(safe-area-inset-bottom, 0px) + 16px);
        right: 16px;
        width: 48px;
        height: 48px;
    }

    .floating-upload-button i {
        font-size: 20px;
    }

    .btn.btn-success.d-sm-none {
        display: flex !important;
        padding: 0.25rem 0.5rem !important;
        font-size: var(--small-screen-font-size) !important;
        border: none !important;
        height: 31px !important;
        width: 31px !important;
        align-items: center !important;
        justify-content: center !important;
        margin: 0 !important;
    }

    .btn.btn-success.d-sm-none:hover {
        opacity: 0.8;
    }

    .btn.btn-success.d-sm-none i {
        font-size: 16px;
    }

    .language-selector-container {
        margin-top: -0.5rem;
        margin-bottom: 1.5rem;
    }
}

/* Language Selector Dropdown */
.dropdown {
    position: relative;
}

.dropdown .btn-outline-light:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.dropdown .position-absolute {
    top: 100%;
    right: 0;
    margin-top: 2px;
}

.dropdown .btn.active {
    background-color: #f8f9fa;
    color: #0d6efd;
}

.dropdown .btn:hover:not(.active) {
    background-color: #e9ecef;
}

.cursor-pointer {
    cursor: pointer;
}

.salary-info-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,0.5);
    z-index: 1000;
}

.salary-info-popup {
    max-width: 90%;
    width: 400px;
    padding: 1.5rem;
    border-radius: 1rem;
    background-color: var(--main-background-color);
    color: var(--button-background-color);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.salary-info-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.btn.btn-success.d-sm-none {
    display: none;
}

.language-selector-container {
    margin-top: -1rem;
    margin-bottom: 2rem;
}
